import "../styles/homepage.scss";
import { easeOut, motion, useAnimationControls } from "framer-motion";
import SwiperJs from "../components/swiper/swiper";
import React, { useEffect, useState } from "react";

const HomePage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

  const basePath = "../assets/images/homepage/gallery/";
  const slides = [
    {
      image: `${basePath}1.jpg`,
      caption: "THIRD FLOOR",
    },
    {
      image: `${basePath}2.jpg`,
      caption: "THIRD FLOOR",
    },
    {
      image: `${basePath}3.jpg`,
      caption: "THIRD FLOOR",
    },
    {
      image: `${basePath}4.jpg`,
      caption: "THIRD FLOOR",
    },
    {
      image: `${basePath}/5.jpg`,
      caption: "MEETING ROOM",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoChain = useAnimationControls();
  const sequence = async () => {
    if (isMobile) {
      await logoChain.start({ y: "calc(100px)" });
      return await logoChain.start({});
    } else {
      logoChain.start({ scale: 0.5 });
      return await logoChain.start({ y: "calc(-34vh)" });
    }
  };

  useEffect(() => {
    sequence();
  });

  return (
    <>
      <motion.div
        className="overlay"
        animate={{
          opacity: 0,
          display: "none",
        }}
        transition={{ type: "spring", easeOut, duration: 1, delay: 4.5 }}
      >
        <div className="landing-wrapper">
          <div className="body"></div>
        </div>
      </motion.div>
      <section className="one-wrapper">
        <div className="top-half">
          <motion.img
            animate={logoChain}
            transition={{
              ease: "easeOut",
              duration: 2,
              delay: 2,
            }}
            src="../assets/videos/uh.gif"
            alt="Logo"
            className="intro-logo"
          />
      
          <img
            src="../assets/images/homepage/1.jpg"
            alt="Union House Exterior"
            className="exterior"
          />


          <div className="logoholder"></div>
        </div>
        <div className="bottom-half">
          <h2>
            4,902 SQ FT FULLY FITTED
            <br />
            REFURBISHED OFFICE SPACE
          </h2>
        </div>
      </section>

      <section className="two-wrapper">
        <SwiperJs slides={slides} name={"home-swiper"} />
      </section>
    </>
  );
};
export default HomePage;
